//CSS Transitions
@mixin transition($transition) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

//Media Queries
@mixin desktop-lg {
  @media (min-width: #{$desktop-lg}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$desktop-lg - 1px}) {
    @content;
  }
}
@mixin tablet-lg {
  @media (min-width: #{$tablet-lg}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-lg- { // Large Tablet and all smaller
  @media (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$tablet-lg - 1px}) {
    @content;
  }
}
@mixin tablet- { // Tablet and all smaller
  @media (max-width: #{$tablet-lg - 1px}) {
    @content;
  }
}
@mixin mobile-lg {
  @media (min-width: #{$mobile-lg}) and (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin mobile-lg- { // Large Phone and smaller
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile-lg - 1px}) {
    @content;
  }
}
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
     -moz-box-sizing: $box-model; // Firefox <= 19
          box-sizing: $box-model;
}

//Border Radius
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
          border-radius: $radius;
    background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}
// Single side border-radius
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
          border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
           border-top-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
          border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
             border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
          border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
           border-bottom-left-radius: $radius;
     background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
          border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
             border-top-left-radius: $radius;
    background-clip: padding-box;
}
